<div id="vtp-monthly-invoice" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="subtitle secondary-text">
                        <span>VTP Monthly Invoice Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="monthlyInvoiceForm" [formGroup]="monthlyInvoiceForm"
                    class="vtp-monthly-invoice w-100-p" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex fusePerfectScrollbar>

                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Select Vocational Training Provider</mat-label>

                                <mat-select formControlName="VTPId" appearance="outline" required
                                    matTooltip="Select Vocational Training Provider" matTooltipPosition='above'
                                    matTooltipClass="allow-cr" [disabled]="PageRights.IsReadOnly" #SelectVt>
                                    <mat-select-filter class="select-filter" [array]="vtpList"
                                        (filteredReturn)="this.filteredVTPItems = $event" [displayMember]="'Name'">
                                    </mat-select-filter>
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of filteredVTPItems">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="monthlyInvoiceForm.controls.VTPId.invalid">
                                    {{getErrorMessage(monthlyInvoiceForm, 'VTPId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Report Month</mat-label>

                                <input matInput name="reportDate" formControlName="ReportDate" required
                                    [matDatepicker]="reportDate" [max]="CurrentDate" [readonly]="true" (click)="reportDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                                <mat-datepicker #reportDate startView="year" (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event, reportDate)"></mat-datepicker>

                                <mat-error *ngIf="monthlyInvoiceForm.controls.ReportDate.invalid">
                                    {{getErrorMessage(monthlyInvoiceForm, 'ReportDate')}}
                                </mat-error>
                            </mat-form-field>

                            <span class="material-icons action-btn"
                                (click)="GetVTPMonthlyInvoiceReport()">save_alt</span>
                        </div>

                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>