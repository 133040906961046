<div id="student-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    STUDENT REGISTRATIONS
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form name="studentSearchForm" [formGroup]="studentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="SearchText" placeholder="Search for students"
                            matTooltip="Search By School Name, UDISE, Student Name" matTooltipPosition='above'
                            matTooltipClass="allow-cr">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                    <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                </a>

                <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                    replaceUrl="false" class="add-student-class-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="fill"
                                (selectionChange)="onChangeSector($event.value)">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Job Role</mat-label>
                            <mat-select formControlName="JobRoleId" name="jobRoleId" appearance="fill" >
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label> Academic Year</mat-label>
                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>
                            <mat-select formControlName="ClassId" name="classId" appearance="fill">
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Section</mat-label>
                            <mat-select formControlName="SectionId" name="sectionId" appearance="fill">
                                <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <div class="clear-filter" fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadStudentsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table class="student-class-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                 <!-- StudentUniqueNumber Column -->
                 <ng-container matColumnDef="StudentUniqueNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Unique Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.StudentUniqueNumber}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassSectionName Column -->
                <ng-container matColumnDef="ClassSection">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Class Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.ClassSection}}</p>
                    </mat-cell>
                </ng-container>
                
                <!-- VocationalSectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Vocational Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTEmailId Column -->
                <ng-container matColumnDef="VTEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Email ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.VTEmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentName Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.StudentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CreatedBy Column -->
                <ng-container matColumnDef="CreatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>CreatedBy
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.CreatedBy}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UpdatedBy Column -->
                <ng-container matColumnDef="UpdatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> UpdatedBy
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.UpdatedBy}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentUniqueId Column -->
                <ng-container matColumnDef="StudentUniqueId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Unique Id
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.StudentUniqueId}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- Mobile Column -->
                    <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- WhatappNo Column -->
                    <ng-container matColumnDef="WhatappNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>WhatsApp No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.WhatappNo}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- Second Mobile No Column -->
                    <ng-container matColumnDef="SecondMobileNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Second Mobile No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.SecondMobileNo}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsStudentVE9And10 Column -->
                <ng-container matColumnDef="IsStudentVE9And10">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Did students have a VE in 9th & 10th?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.IsStudentVE9And10}}</p>
                    </mat-cell>
                </ng-container>
            
                    <!-- CWSNStatus Column -->
                    <ng-container matColumnDef="CWSNStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>CWSNStatus
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.CWSNStatus}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRoleName Column -->
                <ng-container matColumnDef="JobRoleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>JobRole Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- Father Name Column -->
                    <ng-container matColumnDef="FatherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Father Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.FatherName}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- Mother Name Column -->
                    <ng-container matColumnDef="MotherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mother Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.MotherName}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- Guardian Name Column -->
                    <ng-container matColumnDef="GuardianName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Guardian Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.GuardianName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AssessmentToBeConducted Column -->
                <ng-container matColumnDef="AssessmentToBeConducted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Assessment To Be Conducted
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.AssessmentToBeConducted}}</p>
                    </mat-cell>
                </ng-container>

                    <!-- DateOfBirth Column -->
                    <ng-container matColumnDef="DateOfBirth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Birth
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfBirth | date: "dd/MM/yyyy"}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsSameStudentTrade Column -->
                <ng-container matColumnDef="IsSameStudentTrade">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is the student continuing the same trade?
                </mat-header-cell>
                <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                    <p class="text-truncate">{{studentClass.IsSameStudentTrade}}</p>
                </mat-cell>
                </ng-container>

                <!-- SocialCategory Column -->
                <ng-container matColumnDef="SocialCategory">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>SocialCategory
                </mat-header-cell>
                <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                    <p class="text-truncate">{{studentClass.SocialCategory}}</p>
                </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stream Column -->
                <ng-container matColumnDef="Stream">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Stream
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Stream}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfEnrollment Column -->
                <ng-container matColumnDef="DateOfEnrollment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Enrollment
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfEnrollment | date: Constants.ShortDateFormat }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfEnrollment Column -->
                <ng-container matColumnDef="DateOfDropout">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Dropout
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfDropout | date: Constants.ShortDateFormat }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef class="mat-header-center">Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" class="text-center">
                        <mat-icon *ngIf="studentClass.IsActive" class="data-active s-20">check</mat-icon>
                        <mat-icon *ngIf="!studentClass.IsActive" class="data-inactive s-20">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs class="mat-header-center">Actions</mat-header-cell>

                    <mat-cell *matCellDef="let studentClass" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-registrations', 'view', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-view s-22">visibility</mat-icon>
                        </button>
                        <button
                            *ngIf="(PageRights.IsEdit && studentClass.DeletedBy == null)  || UserModel.RoleCode == 'SUR'"
                            mat-icon-button [routerLink]="['/student-registrations', 'edit', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-edit s-20">edit</mat-icon>
                        </button>
                        <button
                            *ngIf="(PageRights.IsDelete && studentClass.DeletedBy == null)  || UserModel.RoleCode == 'SUR'"
                            mat-icon-button color="warn" (click)="onDeleteStudentClass(studentClass.StudentId)">
                            <mat-icon class="data-delete s-22">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentClass; columns: displayedColumns;" class="student-class" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>