<div id="genericvtmapping" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VTP/VC/VT MAPPING
                    <a [routerLink]="['./']" skipLocationChange="true" replaceUrl="false"
                        class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a VTP/VC/VT MAPPING">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button *ngIf="PageRights.IsAdd" mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-genericvtmapping-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'GenericVTMappings', sheet: 'GenericVTMappings', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

         <!-- Filter Section -->
         <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="genericvtmappingFilterForm" [formGroup]="genericvtmappingFilterForm"  fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorId" name="sectorId" appearance="fill"
                                (selectionChange)="onChangeSector($event.value)">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Job Role</mat-label>

                            <mat-select formControlName="JobRoleId" name="jobRoleId" appearance="fill" >
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <div class="clear-filter" fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadGenericVTMappingByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="genericvtmapping-table responsive-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[8]"
            matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                <ng-container matColumnDef="VTPShortName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP ShortName</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.VTPShortName}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="VCFullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC FullName</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.VCFullName}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="VCEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Email</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.VCEmailId}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="VTFullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT FullName</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.VTFullName}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="VTEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Email</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.VTEmail}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- SchoolName Column -->
                 <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Name</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SectorName</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- JobRoleName Column -->
                <ng-container matColumnDef="JobRoleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>JobRole Name</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>               

                <!-- DateOfAllocation Column -->
                <ng-container matColumnDef="DateOfAllocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date of School VTP Allocation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{genericvtmapping.DateOfAllocation | date: Constants.ShortDateFormat}}
                        </p>
                    </mat-cell>
                </ng-container>


                <!-- DateOfAllocationVC Column -->
                <ng-container matColumnDef="DateOfAllocationVC">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date of School VC Allocation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{genericvtmapping.DateOfAllocationVC | date: Constants.ShortDateFormat}}
                        </p>
                    </mat-cell>
                </ng-container>

                    <!-- DateOfRemoval Column -->
                    <ng-container matColumnDef="DateOfRemoval">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Removal
                        </mat-header-cell>
                        <mat-cell *matCellDef="let genericvtmapping" fxHide fxShow.gt-xs>
                            <p class="text-truncate">{{genericvtmapping.DateOfRemoval | date: Constants.ShortDateFormat}}
                            </p>
                        </mat-cell>
                    </ng-container>

                 <!-- CreatedBy Column -->
                 <ng-container matColumnDef="CreatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>CreatedBy</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.CreatedBy}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- UpdatedBy Column -->
                 <ng-container matColumnDef="UpdatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UpdatedBy</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping">
                        <p class="text-truncate">{{genericvtmapping.UpdatedBy}}</p>
                    </mat-cell>
                </ng-container>

                  <!-- IsActive Column -->
                  <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="genericvtmapping.IsActive" class="data-active s-20">check</mat-icon>
                        <mat-icon *ngIf="!genericvtmapping.IsActive" class="data-inactive s-20">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs class="mat-header-center">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let genericvtmapping" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/genericvtmappings', 'view', genericvtmapping.GenericVTMappingId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon class = "data-view s-20">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && genericvtmapping.IsActive" mat-icon-button
                            [routerLink]="['/genericvtmappings', 'edit', genericvtmapping.GenericVTMappingId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon class="data-edit s-20">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteGenericVTMapping(genericvtmapping.GenericVTMappingId)"
                            *ngIf="PageRights.IsDelete && genericvtmapping.IsActive">
                            <mat-icon class="data-delete s-20">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let genericvtmapping; columns: displayedColumns;" class="genericvtmapping" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>