<div id="student-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/student-registrations'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ studentClassModel.FullName }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Student Registration
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Student Registration Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-student-class-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateStudentClassDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-student-class-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateStudentClassDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="studentClassForm" [formGroup]="studentClassForm" class="student-class w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student Unique Number</mat-label>
                            <input matInput name="StudentUniqueNumber" formControlName="StudentUniqueNumber" digitOnly [disabled]>
                            <mat-error *ngIf="studentClassForm.controls.StudentUniqueNumber.invalid">
                                {{getErrorMessage(studentClassForm, 'StudentUniqueNumber')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="80" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolId" required
                                appearance="outline" matTooltip="Select School" matTooltipPosition='above'
                                matTooltipClass="allow-cr" [disabled]="PageRights.IsReadOnly" #SelectSchool
                                (selectionChange)="onChangeSchool($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.SchoolId.invalid">
                                {{getErrorMessage(studentClassForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Sector</mat-label>
                            
                            <mat-select formControlName="SectorId" required appearance="outline"
                                (selectionChange)="onChangeSector($event.value)" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.SectorId.invalid">
                                {{getErrorMessage(studentClassForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select name="jobRoleId" formControlName="JobRoleId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeJobRole($event.value)">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(studentClassForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeAcademicYear($event.value)">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(studentClassForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Class</mat-label>

                            <mat-select formControlName="ClassId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeClass($event.value)">
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.ClassId.invalid">
                                {{getErrorMessage(studentClassForm, 'ClassId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Vocational Section</mat-label>

                            <mat-select formControlName="SectionId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.SectionId.invalid">
                                {{getErrorMessage(studentClassForm, 'SectionId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Class Section</mat-label>

                            <mat-select formControlName="ClassSection" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of classSectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.ClassSection.invalid">
                                {{getErrorMessage(studentClassForm, 'ClassSection')}}
                            </mat-error>
                        </mat-form-field>
                        
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>First Name</mat-label>
                            <input matInput name="firstName" formControlName="FirstName" required
                                placeholder="Ex. Rakesh" matTooltip="First letter should be capital."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.FirstName.invalid">
                                {{getErrorMessage(studentClassForm, 'FirstName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Middle Name</mat-label>
                            <input matInput name="middleName" formControlName="MiddleName" placeholder="Ex. Kumar"
                                matTooltip="First letter should be capital." matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.MiddleName.invalid">
                                {{getErrorMessage(studentClassForm, 'MiddleName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Last Name</mat-label>
                            <input matInput name="lastName" formControlName="LastName"
                                [required]="appInfo.target == 'lahi'" placeholder="Ex. Nayak"
                                matTooltip="First letter should be capital." matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.LastName.invalid">
                                {{getErrorMessage(studentClassForm, 'LastName')}}
                            </mat-error>
                        </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Student Unique Id</mat-label>
                        <input matInput name="StudentUniqueId" formControlName="StudentUniqueId" required
                            maxlength="25" placeholder="Ex. Ab0098000">
                        <mat-error *ngIf="studentClassForm.controls.StudentUniqueId.invalid">
                            {{getErrorMessage(studentClassForm, 'StudentUniqueId')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label>Gender</mat-label>
                        <mat-select name="gender" formControlName="Gender" required appearance="outline"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                {{ genderItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="studentClassForm.controls.Gender.invalid">
                            {{getErrorMessage(studentClassForm, 'Gender')}}
                        </mat-error>
                    </mat-form-field>

                <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50"
                    fxFlex.lt-sm="100">
                    <mat-label>Date Of Birth</mat-label>
                    <input matInput name="dateOfBirth" formControlName="DateOfBirth" required
                        [max]="MaxDateOfBirthStudent" [matDatepicker]="dateOfBirth" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfBirth.open()">
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
    
                    <mat-error *ngIf="studentClassForm.controls.DateOfBirth.invalid">
                        {{getErrorMessage(studentClassForm, 'DateOfBirth')}}
                    </mat-error>
                </mat-form-field>
                </div>
                    
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">

                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Father Name</mat-label>
                        <input matInput name="fatherName" formControlName="FatherName" required
                            placeholder="Ex. Rakesh" matTooltip="First letter should be capital."
                            matTooltipPosition="above">
                        <mat-error *ngIf="studentClassForm.controls.FatherName.invalid">
                            {{getErrorMessage(studentClassForm, 'FatherName')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Mother Name</mat-label>
                        <input matInput name="motherName" formControlName="MotherName" required placeholder="Ex. Kumari"
                            matTooltip="First letter should be capital." matTooltipPosition="above">
                        <mat-error *ngIf="studentClassForm.controls.MotherName.invalid">
                            {{getErrorMessage(studentClassForm, 'MotherName')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Guardian Name</mat-label>
                        <input matInput name="guardianName" formControlName="GuardianName" placeholder="Nayak"
                            matTooltip="First letter should be capital." matTooltipPosition="above">
                        <mat-error *ngIf="studentClassForm.controls.GuardianName.invalid">
                            {{getErrorMessage(studentClassForm, 'GuardianName')}}
                        </mat-error>
                    </mat-form-field>
                </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Assessment To Be Conducted</mat-label>
                            <mat-select name="assessmentToBeConducted" formControlName="AssessmentToBeConducted"
                                required appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.AssessmentToBeConducted.invalid">
                                {{getErrorMessage(studentClassForm, 'AssessmentToBeConducted')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Stream</mat-label>
                         
                            <mat-select name="stream" formControlName="Stream"
                            [required]= "selectedClass === 'Class 11' || selectedClass === 'Class 12'"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Science PCB">Science PCB</mat-option>
                                <mat-option value="Science PCM">Science PCM</mat-option>
                                <mat-option value="Science PCMB">Science PCMB</mat-option>
                                <mat-option value="Arts & Humanities">Arts & Humanities</mat-option>
                                <mat-option value="Commerce">Commerce</mat-option>
                                <mat-option value="Others">Others</mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.Stream.invalid">
                                {{getErrorMessage(studentClassForm, 'Stream')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>First Mobile Number</mat-label>
                        <input matInput name="mobile" formControlName="Mobile" digitOnly minlength="10"
                            maxlength="10" placeholder="Ex. 9800098000"  required >
                        <mat-error *ngIf="studentClassForm.controls.Mobile.invalid">
                            {{getErrorMessage(studentClassForm, 'Mobile')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Second Mobile Number</mat-label>
                        <input matInput name="SecondMobileNo" formControlName="SecondMobileNo" digitOnly minlength="10" [required]="studentClassForm.get('AssessmentToBeConducted').value === 'Yes'"
                            maxlength="10" placeholder="Ex. 9800098000">
                        <mat-error *ngIf="studentClassForm.controls.SecondMobileNo.invalid">
                            {{getErrorMessage(studentClassForm, 'SecondMobileNo')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>WhatappNo</mat-label>
                        <input matInput name="whatappNo" formControlName="WhatappNo" digitOnly minlength="10"
                            maxlength="10" placeholder="Ex. 9800098000">
                        <mat-error *ngIf="studentClassForm.controls.WhatappNo.invalid">
                            {{getErrorMessage(studentClassForm, 'WhatappNo')}}
                        </mat-error>
                    </mat-form-field>
                </div>


            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                fxLayoutGap.lt-md="0px">

                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                    <mat-label>CWSN Status</mat-label>
                    <mat-select name="cwsnStatus" formControlName="CWSNStatus"
                        required appearance="outline" [disabled]="PageRights.IsReadOnly">
                        <mat-option value="Yes">Yes</mat-option>
                        <mat-option value="No">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="studentClassForm.controls.CWSNStatus.invalid">
                        {{getErrorMessage(studentClassForm, 'CWSNStatus')}}
                    </mat-error>
                </mat-form-field>

                    <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                    fxFlex.lt-sm="100">
                    <mat-label>Social Category</mat-label>
                    <mat-select name="socialCategory" formControlName="SocialCategory" required
                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                        <mat-option [value]="socialCategoryItem.Id"
                            *ngFor="let socialCategoryItem of  socialCategoryList">
                            {{ socialCategoryItem.Name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="studentClassForm.controls.SocialCategory.invalid">
                        {{getErrorMessage(studentClassForm, 'SocialCategory')}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                    <mat-label>Date Of Enrollment</mat-label>
                    <input matInput [matDatepicker]="dateOfEnrollment" name="dateOfEnrollment" required
                        [max]="CurrentDate" formControlName="DateOfEnrollment"
                        [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfEnrollment.open()">
                    <mat-datepicker-toggle matSuffix [for]="dateOfEnrollment"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfEnrollment></mat-datepicker>
                    <mat-error *ngIf="studentClassForm.controls.DateOfEnrollment.invalid">
                        {{getErrorMessage(studentClassForm, 'DateOfEnrollment')}}
                    </mat-error>
                </mat-form-field>

            </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                        <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                            fxFlex.lt-sm="100%">
                            <mat-label>Date Of Dropout</mat-label>
                            <input matInput [matDatepicker]="dateOfDropout" name="dateOfDropout" [max]="CurrentDate"
                                (dateChange)="onChangeDateEnableDisableCheckBox(studentClassForm, $event)"
                                [min]="studentClassForm.controls.DateOfEnrollment.value" formControlName="DateOfDropout"
                                [disabled]="true" [readonly]="true" (click)="dateOfDropout.open()">
                            <mat-datepicker-toggle matSuffix [for]="dateOfDropout"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfDropout></mat-datepicker>
                            <mat-error *ngIf="studentClassForm.controls.DateOfDropout.invalid">
                                {{getErrorMessage(studentClassForm, 'DateOfDropout')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-icon matDatepickerToggleIcon
                            (click)="clearResignationDateInFormControl(studentClassForm, 'DateOfDropout', $event)"
                            *ngIf="studentClassForm.controls.DateOfDropout.value && PageRights.ActionType !='view'">
                            clear</mat-icon>

                        <mat-form-field appearance="outline" fxFlex="0 1 calc(65%)" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="studentClassForm.controls.DateOfDropout.value">
                            <mat-label>Dropout Reason</mat-label>
                            <input matInput name="dropoutReason" formControlName="DropoutReason"
                                [required]="studentClassForm.controls.DateOfDropout.value != null || studentClassForm.controls.DateOfDropout.value != ''"
                                [disabled]="true"
                                >
                                <!-- [disabled]="studentClassForm.controls.DateOfDropout.value == null || studentClassForm.controls.DateOfDropout.value == ''" -->
                            <mat-error *ngIf="studentClassForm.controls.DropoutReason.invalid"
                            >
                                {{getErrorMessage(studentClassForm, 'DropoutReason')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label fxLayoutGap="20px">Did students have a VE in 9th & 10th?</mat-label>
                        <mat-radio-group fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px"
                            formControlName="IsStudentVE9And10" (change)="onChangeStudentVE9And10()"
                            [required]="selectedClass == 'Class 11'|| selectedClass == 'Class 12'"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>

                            <mat-error *ngIf="studentClassForm.controls.IsStudentVE9And10.invalid">
                                {{getErrorMessage(studentClassForm, 'IsStudentVE9And10')}}
                            </mat-error>
                        </mat-radio-group>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label>Is the student continuing the same trade?</mat-label>
                        <mat-radio-group fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px"
                            formControlName="IsSameStudentTrade"
                            [required]="studentClassForm.controls.IsStudentVE9And10.value === 'Yes' && (selectedClass === 'Class 11' || selectedClass === 'Class 12')"
                            [disabled]="PageRights.IsReadOnly || (studentClassForm.controls.IsStudentVE9And10.value === 'No' && selectedClass !== 'Class 11' && selectedClass !== 'Class 12')">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>

                            <mat-error *ngIf="studentClassForm.controls.IsSameStudentTrade.invalid">
                                {{getErrorMessage(studentClassForm, 'IsSameStudentTrade')}}
                            </mat-error>
                        </mat-radio-group>
                    </div>

                    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="true">
                        </mat-checkbox>
                    </div> -->

                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>