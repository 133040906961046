<div id="setting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="subtitle secondary-text">
                        <span>Setting Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="settingForm" [formGroup]="settingForm" class="setting w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>User Type</mat-label>

                                <mat-select formControlName="RoleId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeRole($event.value)">
                                    <mat-option [value]="roleItem.Id" *ngFor="let roleItem of roleList">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="settingForm.controls.RoleId.invalid">
                                    {{getErrorMessage(settingForm, 'RoleId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Select User</mat-label>

                                <mat-select formControlName="UserId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" #SelectUserId>
                                    <mat-select-filter class="select-filter" *ngIf="SelectUserId.focused"
                                        [array]="userList" (filteredReturn)="this.filteredUserItems = $event"
                                        [displayMember]="'Name'">
                                    </mat-select-filter>
                                    <mat-option [value]="userItem.Id" *ngFor="let userItem of filteredUserItems">
                                        {{ userItem.Name }}
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="settingForm.controls.UserId.invalid">
                                    {{getErrorMessage(settingForm, 'UserId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <button mat-raised-button class="submit-button" color="accent" (click)="saveSettings()">
                                <span>SAVE</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>