import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VTDailyReportingModel } from './vt-daily-reporting.model';
import { VTDailyReportingService } from './vt-daily-reporting.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';
import { forEach } from 'lodash';

@Component({
  selector: 'data-list-view',
  templateUrl: './vt-daily-reporting.component.html',
  styleUrls: ['./vt-daily-reporting.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VTDailyReportingComponent extends BaseListComponent<VTDailyReportingModel> implements OnInit {
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private vtDailyReportingService: VTDailyReportingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.vtdailyReportingFilterForm = this.createvtdailyReportingFilterForm();
  }
  monthList: [DropdownModel];
  vtdailyReportingFilterForm: FormGroup;
  vtlist : any;
  showAlert: boolean = false;
  monthName: any;
  monthNumber: any;

  ngOnInit(): void {
    this.commonService.GetMasterDataByType({ DataType: 'VTByHm', ParentId: this.UserModel.UserTypeId, SelectTitle: 'Vocational Trainer' }).subscribe(response => {
      if (response.Success) {
        this.vtlist = response.Results;
      }
    });

    this.vtDailyReportingService
      .getDropdownForVTDailyReporting(this.UserModel)
      .subscribe(results => {
        this.monthList = results[2].Results
      });

    let CurrDate = new Date(Date.now());
    let dayNumber = CurrDate.getDate();
    this.monthNumber = CurrDate.getMonth() + 1;
    let monthName = CurrDate.toLocaleString('default', { month: 'long' });

    //testingStart
      let dateString = "01/05/2024";
      let parts = dateString.split('/');
      CurrDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
      monthName = CurrDate.toLocaleString('default', { month: 'long' });
      dayNumber = CurrDate.getDate();
    //testingEnd
  
    if (dayNumber >= 1 && dayNumber <= 5) {
      this.showAlert = true;
      this.monthName = monthName;
      this.monthNumber = CurrDate.getMonth();
    }

    let vtDailyRepotingParams: any = {
      UserTypeId: this.UserModel.UserTypeId,
      vtId: this.vtdailyReportingFilterForm.controls["VTId"].value,
      hmReviewStatus:this.vtdailyReportingFilterForm.controls["HMReviewStatus"].value,
      monthId: this.vtdailyReportingFilterForm.controls['MonthId'].value ? this.vtdailyReportingFilterForm.controls['MonthId'].value : this.monthNumber,
      name: null,
      charBy: null,
      pageIndex: this.SearchBy.PageIndex,
      pageSize: this.SearchBy.PageSize
    };

    this.vtDailyReportingService.GetAllByCriteria(vtDailyRepotingParams).subscribe(response => {
      response.Results.forEach(result => {
        if (result.IsLocked) {
          result.HMReviewLabel = 'Default Approved'; // Use "Default Approved" if IsLocked is true
          result.HMReview = '3'; 
        } else {
          switch (result.HMReview) {
            case "0":
              result.HMReviewLabel = 'Not-Reviewed';
              break;
            case "1":
              result.HMReviewLabel = 'Approved';
              break;
            case "2":
              result.HMReviewLabel = 'Rejected';
              break;
            default:
              result.HMReviewLabel = 'Unknown'; // Handle unexpected values
              break;
          }
        }
      });
      
      
      this.displayedColumns = ['SchoolName', 'SectorName', 'JobRoleName', 'ReportingDate', 'ReportType', 'WorkTypes', 'Remarks','CreatedBy','CreatedOn', 'HMReview', 'Actions'];
      this.tableDataSource.data = response.Results;

      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  } 

  onLoadDailyReportingByFilters(): any {
   
    this.IsLoading = true;

    let vtDailyRepotingParams: any = {
      UserTypeId: this.UserModel.UserTypeId,
      vtId: this.vtdailyReportingFilterForm.controls["VTId"].value,
      hmReviewStatus:this.vtdailyReportingFilterForm.controls["HMReviewStatus"].value,
      monthId: this.vtdailyReportingFilterForm.controls['MonthId'].value ? this.vtdailyReportingFilterForm.controls['MonthId'].value : this.monthNumber,
      name: null,
      charBy: null,
      pageIndex: this.SearchBy.PageIndex,
      pageSize: this.SearchBy.PageSize
    };

    console.log(vtDailyRepotingParams.monthId);

    this.vtDailyReportingService.GetAllByCriteria(vtDailyRepotingParams).subscribe(response => {
      response.Results.forEach(result => {
        if (result.IsLocked) {
          result.HMReviewLabel = 'Default Approved'; // Use "Default Approved" if IsLocked is true
          result.HMReview = '3'; 
        } else {
          switch (result.HMReview) {
            case "0":
              result.HMReviewLabel = 'Not-Reviewed';
              break;
            case "1":
              result.HMReviewLabel = 'Approved';
              break;
            case "2":
              result.HMReviewLabel = 'Rejected';
              break;
            default:
              result.HMReviewLabel = 'Unknown'; // Handle unexpected values
              break;
          }
        }
      });

      this.displayedColumns = ['SchoolName', 'SectorName', 'JobRoleName', 'ReportingDate', 'ReportType', 'WorkTypes', 'Remarks','CreatedBy', 'HMReview', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadVTDailyReportingByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadDailyReportingByFilters();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.vtdailyReportingFilterForm.reset();
    // this.filteredVCItems = [];

    // this.onLoadVocationalTrainersByCriteria();
    // this.onLoadVTDailyReportingByFilters.reset();

    this.onLoadDailyReportingByFilters();
  }

  onDeleteVTDailyReporting(vtDailyReportingId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.vtDailyReportingService.deleteVTDailyReportingById(vtDailyReportingId)
            .subscribe((vtDailyReportingResp: any) => {

              this.zone.run(() => {
                if (vtDailyReportingResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('VTDailyReporting deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  createvtdailyReportingFilterForm(): FormGroup {
    return this.formBuilder.group({
      VTId: new FormControl(),
      HMReviewStatus: new FormControl(),
      MonthId: new FormControl(),
    });
  }
}
