<div id="forgot-password-history" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/forgot-password-histories'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ forgotPasswordHistoryModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Forgot Password History
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Forgot Password History Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-forgot-password-history-button" [disabled]="forgotPasswordHistoryForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateForgotPasswordHistoryDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-forgot-password-history-button" 
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateForgotPasswordHistoryDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="forgotPasswordHistoryForm" [formGroup]="forgotPasswordHistoryForm" class="forgot-password-history w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Email Id</mat-label>
                                <input matInput name="emailId" formControlName="EmailId">
                                <mat-error *ngIf="forgotPasswordHistoryForm.controls.EmailId.invalid">{{getErrorMessage(forgotPasswordHistoryForm, 'EmailId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Password Reset Url</mat-label>
                                <input matInput name="passwordResetUrl" formControlName="PasswordResetUrl">
                                <mat-error *ngIf="forgotPasswordHistoryForm.controls.PasswordResetUrl.invalid">{{getErrorMessage(forgotPasswordHistoryForm, 'PasswordResetUrl')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>User IPAddress</mat-label>
                                <input matInput name="userIPAddress" formControlName="UserIPAddress">
                                <mat-error *ngIf="forgotPasswordHistoryForm.controls.UserIPAddress.invalid">{{getErrorMessage(forgotPasswordHistoryForm, 'UserIPAddress')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Request Date</mat-label>
                                <input matInput name="requestDate" formControlName="RequestDate">
                                <mat-error *ngIf="forgotPasswordHistoryForm.controls.RequestDate.invalid">{{getErrorMessage(forgotPasswordHistoryForm, 'RequestDate')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Reset Password Date</mat-label>
                                <input matInput name="resetPasswordDate" formControlName="ResetPasswordDate">
                                <mat-error *ngIf="forgotPasswordHistoryForm.controls.ResetPasswordDate.invalid">{{getErrorMessage(forgotPasswordHistoryForm, 'ResetPasswordDate')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
