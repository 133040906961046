<div id="vc-school-visit-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VC SCHOOL VISITS REPORTING
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a vc school visits">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                class="add-vc-school-visit-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vc-school-visit-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting">
                        <p class="text-truncate">{{vcSchoolVisitReporting.VCName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting">
                        <p class="text-truncate">{{vcSchoolVisitReporting.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vcSchoolVisitReporting.SchoolName }}</p>
                    </mat-cell>
                </ng-container>

                <!-- DistrictName Column -->
                <ng-container matColumnDef="DistrictName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>District Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vcSchoolVisitReporting.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VisitDate Column -->
                <ng-container matColumnDef="VisitDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Visit Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vcSchoolVisitReporting.VisitDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalBoys Column -->
                <ng-container matColumnDef="TotalBoys">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vcSchoolVisitReporting.TotalBoys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalGirls Column -->
                <ng-container matColumnDef="TotalGirls">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Total Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vcSchoolVisitReporting.TotalGirls}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vcSchoolVisitReporting" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vc-school-visit-reporting', 'view', vcSchoolVisitReporting.VCSchoolVisitReportingId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-view s-22">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/vc-school-visit-reporting', 'edit', vcSchoolVisitReporting.VCSchoolVisitReportingId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-edit s-22">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteVCSchoolVisitReport(vcSchoolVisitReporting.VCSchoolVisitReportingId)" *ngIf="PageRights.IsDelete">
                            <mat-icon class = "data-delete s-20">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vcSchoolVisit; columns: displayedColumns;" class="vc-school-visit" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>