<div id="transaction" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/transactions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ transactionModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Transaction
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Transaction Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-transaction-button" [disabled]="transactionForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateTransactionDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-transaction-button" 
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateTransactionDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="transactionForm" [formGroup]="transactionForm" class="transaction w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Code</mat-label>
                                <input matInput name="code" formControlName="Code" required>
                                <mat-error *ngIf="transactionForm.controls.Code.invalid">{{getErrorMessage(transactionForm, 'Code')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Name</mat-label>
                                <input matInput name="name" formControlName="Name" required>
                                <mat-error *ngIf="transactionForm.controls.Name.invalid">{{getErrorMessage(transactionForm, 'Name')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Page Title</mat-label>
                                <input matInput name="pageTitle" formControlName="PageTitle" required>
                                <mat-error *ngIf="transactionForm.controls.PageTitle.invalid">{{getErrorMessage(transactionForm, 'PageTitle')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Page Description</mat-label>
                                <input matInput name="pageDescription" formControlName="PageDescription">
                                <mat-error *ngIf="transactionForm.controls.PageDescription.invalid">{{getErrorMessage(transactionForm, 'PageDescription')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Url Action</mat-label>
                                <input matInput name="urlAction" formControlName="UrlAction">
                                <mat-error *ngIf="transactionForm.controls.UrlAction.invalid">{{getErrorMessage(transactionForm, 'UrlAction')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Url Controller</mat-label>
                                <input matInput name="urlController" formControlName="UrlController">
                                <mat-error *ngIf="transactionForm.controls.UrlController.invalid">{{getErrorMessage(transactionForm, 'UrlController')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Url Para</mat-label>
                                <input matInput name="urlPara" formControlName="UrlPara">
                                <mat-error *ngIf="transactionForm.controls.UrlPara.invalid">{{getErrorMessage(transactionForm, 'UrlPara')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Route Url</mat-label>
                                <input matInput name="routeUrl" formControlName="RouteUrl">
                                <mat-error *ngIf="transactionForm.controls.RouteUrl.invalid">{{getErrorMessage(transactionForm, 'RouteUrl')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Display Order</mat-label>
                                <input matInput name="displayOrder" formControlName="DisplayOrder" required>
                                <mat-error *ngIf="transactionForm.controls.DisplayOrder.invalid">{{getErrorMessage(transactionForm, 'DisplayOrder')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks">
                                <mat-error *ngIf="transactionForm.controls.Remarks.invalid">{{getErrorMessage(transactionForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
