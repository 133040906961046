<div id="hm-issue-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="backToApprovalPage">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ hmIssueReportingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New HM Issue Reporting
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{type}} Issue Reporting Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-hm-issue-reporting-button"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateHMIssueReportingDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-hm-issue-reporting-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateHMIssueReportingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="issueApprovalForm" [formGroup]="issueApprovalForm" class="hm-issue-reporting w-100-p p-24 mr-24" fxLayout="column"
                fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolId" required
                                appearance="outline" matTooltip="Select School" matTooltipPosition='above'
                                matTooltipClass="allow-cr" [disabled]="PageRights.IsReadOnly" #SelectSchool
                                >
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.SchoolId.invalid">
                                {{getErrorMessage(issueApprovalForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Sector</mat-label>
                            
                            <mat-select formControlName="SectorId" required appearance="outline"
                                 [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.SectorId.invalid">
                                {{getErrorMessage(issueApprovalForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select name="jobRoleId" formControlName="JobRoleId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" >
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(issueApprovalForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" appearance="outline" required
                                 [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(issueApprovalForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>
                            <mat-select name="StudentClass" formControlName="StudentClass" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeClasses($event.value)"
                                matTooltip="Select the Class"
                                matTooltipPosition="above">
                                <mat-option disabled="" [value]="">Select Class</mat-option>
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.StudentClass.invalid">
                                {{getErrorMessage(issueApprovalForm, 'StudentClass')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Sections</mat-label>
                            <mat-select name="sectionIds" formControlName="SectionIds" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" multiple>
                                <mat-option disabled="" [value]="">Select Sections</mat-option>
                                <mat-option [value]="sectionItem.Name" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.SectionIds.invalid">
                                {{getErrorMessage(issueApprovalForm, 'SectionIds')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Reporting Date</mat-label>
                                <input matInput [matDatepicker]="issueReportDate" name="issueReportDate" [max]="CurrentDate" 
                                formControlName="IssueReportDate" [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="issueReportDate.open()">
                                <mat-datepicker-toggle matSuffix [for]="issueReportDate"></mat-datepicker-toggle>
                                <mat-datepicker #issueReportDate></mat-datepicker>
                                <mat-error *ngIf="issueApprovalForm.controls.IssueReportDate.invalid">{{getErrorMessage(issueApprovalForm, 'IssueReportDate')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Main Issue</mat-label>
                                <mat-select name="mainIssue" formControlName="MainIssue" appearance="outline" 
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeMainIssue($event.value)">
                                    <mat-option [value]="mainIssueItem.Id"
                                        *ngFor="let mainIssueItem of mainIssueList">
                                        {{ mainIssueItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="issueApprovalForm.controls.MainIssue.invalid">{{getErrorMessage(issueApprovalForm, 'MainIssue')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Sub Issue</mat-label>
                                <mat-select name="subIssue" formControlName="SubIssue" appearance="outline" [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="subIssueItem.Id"
                                        *ngFor="let subIssueItem of subIssueList">
                                        {{ subIssueItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="issueApprovalForm.controls.SubIssue.invalid">{{getErrorMessage(issueApprovalForm, 'SubIssue')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Months Affected</mat-label>
                                <mat-select name="month" formControlName="Month" appearance="outline" multiple [disabled]="PageRights.IsReadOnly" required>
                                    <mat-option [value]="monthItem.Name"
                                        *ngFor="let monthItem of monthList">
                                        {{ monthItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="issueApprovalForm.controls.Month.invalid">{{getErrorMessage(issueApprovalForm, 'Month')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Students Affected</mat-label>
                                <mat-select name="studentType" formControlName="StudentType" appearance="outline" [disabled]="PageRights.IsReadOnly" required>
                                    <mat-option [value]="studentTypeItem.Id"
                                        *ngFor="let studentTypeItem of studentTypeList">
                                        {{ studentTypeItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="issueApprovalForm.controls.StudentType.invalid">{{getErrorMessage(issueApprovalForm, 'StudentType')}}
                                </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>No of Student Affected</mat-label>
                                <input matInput name="noOfStudents" formControlName="NoOfStudents" digitOnly>
                                <mat-error *ngIf="issueApprovalForm.controls.NoOfStudents.invalid">{{getErrorMessage(issueApprovalForm, 'NoOfStudents')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline"  fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Issue Details</mat-label>
                                <input matInput name="issueDetails" formControlName="IssueDetails">
                                <mat-error *ngIf="issueApprovalForm.controls.IssueDetails.invalid">{{getErrorMessage(issueApprovalForm, 'IssueDetails')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Issue Status</mat-label>
                            <mat-select name="approvalStatus" formControlName="ApprovalStatus" appearance="outline"  required>
                                <mat-option [value]="issueStatusItem.Id"
                                    *ngFor="let issueStatusItem of issueStatusList">
                                    {{ issueStatusItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="issueApprovalForm.controls.ApprovalStatus.invalid">{{getErrorMessage(issueApprovalForm, 'ApprovalStatus')}}
                            </mat-error>
                        </mat-form-field>
                        </div>


                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Remarks</mat-label>
                            <input matInput name="remarks" formControlName="Remarks">
                            <mat-error *ngIf="issueApprovalForm.controls.Remarks.invalid">{{getErrorMessage(issueApprovalForm, 'Remarks')}}
                            </mat-error>
                        </mat-form-field>
                        </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
