<div id="schoolsectorjob" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/schoolsectorjobs'" skipLocationChange="true"
                    replaceUrl="false">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ schoolsectorjobModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New SchoolSectorJob
                    </div>
                    <div class="subtitle secondary-text">
                        <span>SchoolSectorJob Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-schoolsectorjob-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateSchoolSectorJobDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-schoolsectorjob-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateSchoolSectorJobDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="schoolsectorjobForm" [formGroup]="schoolsectorjobForm" class="schoolsectorjob w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School</mat-label>

                            <mat-select formControlName="SchoolId" required appearance="outline"
                                matTooltip="Select School" matTooltipPosition='above' matTooltipClass="allow-cr"
                                [disabled]="PageRights.IsReadOnly" #SelectSchool
                                (selectionChange)="onChangeSchool($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.schoolList = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of schoolList">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolsectorjobForm.controls.SchoolId.invalid">
                                {{getErrorMessage(schoolsectorjobForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Sector</mat-label>

                            <mat-select formControlName="SectorId" required appearance="outline"
                                (selectionChange)="onChangeSector($event.value)" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolsectorjobForm.controls.SectorId.invalid">
                                {{getErrorMessage(schoolsectorjobForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select name="jobRoleId" formControlName="JobRoleId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolsectorjobForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(schoolsectorjobForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                         fxLayoutGap.lt-md="0px">
                      <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="33" fxFlex.lt-sm="100">
                        <mat-label>Date Of Allocation</mat-label>
                        <input matInput [matDatepicker]="dateOfAllocation" name="dateOfAllocation" required
                            [min]="minAllocationDate" [max]="CurrentDate" formControlName="DateOfAllocation"
                            [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="dateOfAllocation.open()">
                        <mat-datepicker-toggle matSuffix [for]="dateOfAllocation"></mat-datepicker-toggle>
                        <mat-datepicker #dateOfAllocation></mat-datepicker>
                        <mat-error *ngIf="schoolsectorjobForm.controls.DateOfAllocation.invalid">
                            {{getErrorMessage(schoolsectorjobForm, 'DateOfAllocation')}}
                        </mat-error>
                      </mat-form-field>

                     <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="this.UserModel.RoleCode == 'SUR' && (PageRights.ActionType ==='edit' || PageRights.ActionType ==='view')">
                        <mat-label>Date Of Removal</mat-label>
                        <input matInput [matDatepicker]="picker1" name="dateOfRemoval" [max]="CurrentDate"
                            (dateChange)="onChangeDateEnableDisableCheckBox(schoolsectorjobForm, $event)"
                            [min]="schoolsectorjobForm.controls.DateOfAllocation.value"
                            formControlName="DateOfRemoval" (click)="picker1.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="schoolsectorjobForm.controls.DateOfRemoval.invalid">
                            {{getErrorMessage(schoolsectorjobForm, 'DateOfRemoval')}}
                        </mat-error>
                     </mat-form-field>
                     <!-- <mat-icon matDatepickerToggleIcon
                        (click)="clearResignationDateInFormControl(schoolsectorjobForm, 'DateOfRemoval', $event)"
                        *ngIf="schoolsectorjobForm.controls.DateOfRemoval.value && PageRights.ActionType !='view'">
                        clear</mat-icon> -->
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="PageRights.ActionType !='new'">
                        <span class="checkbox-space">Is Active</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="true">
                        </mat-checkbox>
                    </div>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>