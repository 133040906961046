<div id="account" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="UserModel.LandingPageUrl">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="subtitle secondary-text">
                        <span>Change Password</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-account-button" [disabled]="changePasswordForm.invalid"
                (click)="saveOrUpdateChangePasswordDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="changePasswordForm" [formGroup]="changePasswordForm"
                    class="change-password w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Login Id</mat-label>
                            <input matInput name="userId" formControlName="UserId" required readonly>
                            <mat-error *ngIf="changePasswordForm.controls.UserId.invalid">
                                {{getErrorMessage(changePasswordForm, 'UserId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Current Password</mat-label>
                            <input matInput type="password" name="password" formControlName="Password" required>
                            <mat-error *ngIf="changePasswordForm.controls.Password.invalid">
                                {{getErrorMessage(changePasswordForm, 'Password')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>New Password</mat-label>
                            <!-- <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility> -->
                            <input matInput #password type="password" name="newPassword"
                                formControlName="NewPassword" required 
                                matTooltip="1) Your password must be between 8 and 50 characters.
                                2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                                3) Your password must contain at least one lowercase letter.
                                4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                                5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                                matTooltipPosition='above' matTooltipClass="allow-cr">
                            <mat-error *ngIf="changePasswordForm.controls.NewPassword.invalid">
                                {{getErrorMessage(changePasswordForm, 'NewPassword')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Confirm Password</mat-label>
                            <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                                formControlName="ConfirmPassword" required 
                                matTooltip="1) Your password must be between 8 and 50 characters.
                                2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                                3) Your password must contain at least one lowercase letter.
                                4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                                5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                                matTooltipPosition='above' matTooltipClass="allow-cr">                             
                            <mat-icon matSuffix (click)="setVisibleConfirmPassword()">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                            </mat-icon>

                            <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.invalid">
                                {{getErrorMessage(changePasswordForm, 'ConfirmPassword')}}
                            </mat-error>
                            <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.errors">Password Not
                                Matched</mat-error>
                        </mat-form-field>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>