<div id="vt-issue-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-issue-reporting'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtIssueReportingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Issue Reporting
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Issue Reporting Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-issue-reporting-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateVTIssueReportingDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-issue-reporting-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateVTIssueReportingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtIssueReportingForm" [formGroup]="vtIssueReportingForm"
                    class="vt-issue-reporting w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolId" required
                                appearance="outline" matTooltip="Select School" matTooltipPosition='above'
                                matTooltipClass="allow-cr" [disabled]="PageRights.IsReadOnly" #SelectSchool
                                (selectionChange)="onChangeSchool($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.SchoolId.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            
                            <mat-select formControlName="SectorId" required appearance="outline"
                                (selectionChange)="onChangeSector($event.value)" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.SectorId.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Job Role</mat-label>

                            <mat-select name="jobRoleId" formControlName="JobRoleId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeJobRole($event.value)">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.JobRoleId.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'JobRoleId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" appearance="outline" required
                                (selectionChange)="onChangeAcademicYear($event.value)" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="34" fxFlex.lt-md="50"
                        fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>
                            <mat-select name="StudentClass" formControlName="StudentClass" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeClasses($event.value)"
                                matTooltip="Select the Class"
                                matTooltipPosition="above">
                                <mat-option disabled="" [value]="">Select Class</mat-option>
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.StudentClass.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'StudentClass')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Sections</mat-label>
                            <mat-select name="sectionIds" formControlName="SectionIds" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" multiple>
                                <mat-option disabled="" [value]="">Select Sections</mat-option>
                                <mat-option [value]="sectionItem.Name" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.SectionIds.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'SectionIds')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Reporting Date</mat-label>
                            <input matInput [matDatepicker]="picker" name="issueReportDate" [min]="minReportingDate"
                                [max]="CurrentDate" formControlName="IssueReportDate" required
                                [disabled]="PageRights.IsReadOnly" [readonly]="true" (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="vtIssueReportingForm.controls.IssueReportDate.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'IssueReportDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="65" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Main Issue</mat-label>
                            <mat-select name="mainIssue" formControlName="MainIssue" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeMainIssue($event.value)">
                                <mat-option [value]="mainIssueItem.Id" *ngFor="let mainIssueItem of mainIssueList">
                                    {{ mainIssueItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.MainIssue.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'MainIssue')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Sub Issue</mat-label>
                            <mat-select name="subIssue" formControlName="SubIssue" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="subIssueItem.Id" *ngFor="let subIssueItem of subIssueList">
                                    {{ subIssueItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.SubIssue.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'SubIssue')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Months Affected</mat-label>
                            <mat-select name="month" formControlName="Month" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" multiple required>
                                <mat-option [value]="monthItem.Name" *ngFor="let monthItem of monthList">
                                    {{ monthItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.Month.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'Month')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Students Affected</mat-label>
                            <mat-select name="studentType" formControlName="StudentType" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="studentTypeItem.Id"
                                    *ngFor="let studentTypeItem of studentTypeList">
                                    {{ studentTypeItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.StudentType.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'StudentType')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>No of Student Affected</mat-label>
                            <input matInput name="noOfStudents" formControlName="NoOfStudents" required digitOnly maxlength="4">
                            <mat-error *ngIf="vtIssueReportingForm.controls.NoOfStudents.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'NoOfStudents')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Issue Details</mat-label>
                            <input matInput name="issueDetails" formControlName="IssueDetails">
                            <mat-error *ngIf="vtIssueReportingForm.controls.IssueDetails.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'IssueDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="approvalUrl">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Issue Status</mat-label>
                            <mat-select name="issueStatus" formControlName="IssueStatus" appearance="outline">
                                <mat-option [value]="issueStatusItem.Id"
                                    *ngFor="let issueStatusItem of issueStatusList">
                                    {{ issueStatusItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtIssueReportingForm.controls.IssueStatus.invalid">
                                {{getErrorMessage(vtIssueReportingForm, 'IssueStatus')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>