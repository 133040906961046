<div id="vt-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-classes'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtClassModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Class
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Class Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-class-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateVTClassDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-class-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateVTClassDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtClassForm" [formGroup]="vtClassForm" class="vt-class w-100-p p-24 mr-24" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="UserModel.RoleCode != 'VT'">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPId" [required]="UserModel.RoleCode != 'VT'" appearance="outline"
                                (selectionChange)="onChangeVTP($event.value)" [disabled]="PageRights.IsReadOnly"
                                #SelectVTP>
                                <mat-select-filter class="select-filter" *ngIf="SelectVTP.focused"
                                    [array]="vtpFilterList" (filteredReturn)="vtpList = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.VTPId.invalid">
                                {{getErrorMessage(vtClassForm, 'VTPId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Vocational Coordinator</mat-label>

                            <mat-select formControlName="VCId" [required]="UserModel.RoleCode != 'VT'" appearance="outline"
                                (selectionChange)="onChangeVC($event.value)" [disabled]="PageRights.IsReadOnly"
                                #SelectVC>
                                <mat-select-filter class="select-filter" *ngIf="SelectVC.focused" [array]="VCList"
                                    (filteredReturn)="vcList = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vcItem.Id" *ngFor="let vcItem of vcList">
                                    {{ vcItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.VCId.invalid">
                                {{getErrorMessage(vtClassForm, 'VCId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="UserModel.RoleCode != 'VT'">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School</mat-label>

                            <mat-select formControlName="SchoolId" [required]="UserModel.RoleCode != 'VT'" appearance="outline"
                                matTooltip="Select School" matTooltipPosition='above' matTooltipClass="allow-cr"
                                [disabled]="PageRights.IsReadOnly" #SelectSchool
                                (selectionChange)="onChangeSchool($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.SchoolId.invalid">
                                {{getErrorMessage(vtClassForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Vocational Trainer</mat-label>

                            <mat-select formControlName="VTId" [required]="UserModel.RoleCode != 'VT'" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" #SelectVT>
                                <mat-select-filter class="select-filter" *ngIf="SelectVT.focused" [array]="VTList"
                                    (filteredReturn)="vtList = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vtList">
                                    {{ vtItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.VTId.invalid">
                                {{getErrorMessage(vtClassForm, 'VTId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(vtClassForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Class Name</mat-label>

                            <mat-select formControlName="ClassId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.ClassId.invalid">
                                {{getErrorMessage(vtClassForm, 'ClassId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Section</mat-label>

                            <mat-select formControlName="SectionIds" required multiple appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onSectionChange($event.value)">
                                <mat-option *ngFor="let sectionItem of sectionList;" [value]="sectionItem.Id"
                                    [disabled]="sectionItem.IsDisabled">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtClassForm.controls.SectionIds.invalid">
                                {{getErrorMessage(vtClassForm, 'SectionIds')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>