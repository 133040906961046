<div id="vc-issue-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VC ISSUE REPORTING
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a vc issue reporting">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                replaceUrl="false" class="add-vc-issue-reporting-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vc-issue-reporting-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar>

                <!-- IssueReportDate Column -->
                <ng-container matColumnDef="IssueReportDate">
                    <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        Report Date</mat-header-cell>
                    <mat-cell class="tab-col-15" *matCellDef="let vtIssueReporting">
                        <p class="text-truncate">{{vtIssueReporting.IssueReportDate | date: Constants.ShortDateFormat }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- MainIssue Column -->
                <ng-container matColumnDef="MainIssue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Main Issue
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting">
                        <p class="text-truncate">{{vtIssueReporting.MainIssue}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SubIssue Column -->
                <ng-container matColumnDef="SubIssue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sub Issue
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtIssueReporting.SubIssue}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentType Column -->
                <ng-container matColumnDef="StudentType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtIssueReporting.StudentType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- NoOfStudents Column -->
                <ng-container matColumnDef="NoOfStudents">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>No Of Students
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtIssueReporting.NoOfStudents}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ApprovalStatus Column -->
                <ng-container matColumnDef="ApprovalStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Approval Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtIssueReporting.ApprovalStatus}}</p>
                    </mat-cell>
                </ng-container>

                     <!-- CreatedBy Column -->
                     <ng-container matColumnDef="CreatedBy">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>CreatedBy
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                            <p class="text-truncate">{{vtIssueReporting.CreatedBy}}</p>
                        </mat-cell>
                    </ng-container>

                         <!-- UpdatedBy Column -->
                <ng-container matColumnDef="UpdatedBy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>UpdatedBy
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtIssueReporting.UpdatedBy}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vcIssueReporting" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vc-issue-reporting', 'view', vcIssueReporting.IssueReportingId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-view s-20">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/vc-issue-reporting', 'edit', vcIssueReporting.IssueReportingId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-edit s-20">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteVCIssueReporting(vcIssueReporting.IssueReportingId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon class="data-delete s-20">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vcIssueReporting; columns: displayedColumns;" class="vc-issue-reporting"
                    matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>