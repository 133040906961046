import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { ReportService } from 'app/reports/report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VTPMonthlyInvoiceModel } from './vtp-monthly-invoice.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;
import { MatDatepicker } from '@angular/material/datepicker';

declare var require: any
const fileSaver = require('file-saver');

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'vtp-monthly-invoice',
  templateUrl: './vtp-monthly-invoice.component.html',
  styleUrls: ['./vtp-monthly-invoice.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class VTPMonthlyInvoiceComponent extends BaseListComponent<VTPMonthlyInvoiceModel> implements OnInit {
  monthlyInvoiceForm: FormGroup;
  monthlyAttendanceModel: VTPMonthlyInvoiceModel;
  vtpList: any;
  filteredVTPItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);

    // Set the default school Model
    this.monthlyAttendanceModel = new VTPMonthlyInvoiceModel();
  }

  ngOnInit(): void {
    this.reportService.getDropdownforVTP(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.vtpList = results[0].Results;
        this.filteredVTPItems = this.vtpList.slice();
        console.timeLog("listvalue")
      }

      this.monthlyInvoiceForm = this.createVTPMonthlyInvoiceForm();
    });   

    this.monthlyInvoiceForm = this.createVTPMonthlyInvoiceForm();
  }

  //Create VTMonthlyAttendance form and returns {FormGroup}
  createVTPMonthlyInvoiceForm(): FormGroup {
    return this.formBuilder.group({
      VTPId: new FormControl({ value: (this.UserModel.RoleCode === 'ADM' ? '' : ''), disabled: false }),
      ReportDate: new FormControl({ value: moment(), disabled: false }, Validators.required),
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.monthlyInvoiceForm.get('ReportDate').value;
    ctrlValue.year(normalizedYear.year());
    this.monthlyInvoiceForm.get('ReportDate').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.monthlyInvoiceForm.get('ReportDate').value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year())

    this.monthlyInvoiceForm.get('ReportDate').setValue(ctrlValue);
    datepicker.close();
  }

  GetVTPMonthlyInvoiceReport(): void {
    if (!this.monthlyInvoiceForm.valid) {
      this.validateAllFormFields(this.monthlyInvoiceForm);
      return;
    }

    let vtpId = this.monthlyInvoiceForm.get('VTPId').value;
    let reportDate = this.DateFormatPipe.transform(this.monthlyInvoiceForm.get('ReportDate').value, this.Constants.ServerDateFormat);
    
    var reportParams = {
      UserId: this.UserModel.LoginId,
      VTPId: vtpId,
      RoleId: this.UserModel.RoleCode,
      ReportDate: reportDate
    }

    this.reportService.GetVTPMonthlyInvoiceReport(reportParams).subscribe(response => {

      if (response.Result != null && response.Result != '') {
        let pdfReportUrl = this.Constants.Services.BaseUrl + 'Lighthouse/DownloadReportFile?fileId=' + response.Result + '&folderName=VTPMonthlyInvoicePDF';
        window.open(pdfReportUrl, '_blank', '');
      }
    });
  }
}
