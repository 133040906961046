<div id="hm-issue-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    HM ISSUE APPROVAL
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a hm issue reporting">
                </div>
            </div>
            <!-- / SEARCH -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="hm-issue-reporting-table responsive-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- HMId Column -->
                <ng-container matColumnDef="HeadMasterName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>HOS Name</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting">
                        <p class="text-truncate">{{hmIssueReporting.HeadMasterName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTSchoolSectorId Column -->
                <ng-container matColumnDef="DateOfAllocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Allocation</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.DateOfAllocation | date: "dd/MM/yyyy"}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IssueReportDate Column -->
                <ng-container matColumnDef="IssueReportDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Issue Report Date</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.IssueReportDate | date: "dd/MM/yyyy"}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MainIssue Column -->
                <ng-container matColumnDef="MainIssue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Main Issue</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting">
                        <p class="text-truncate">{{hmIssueReporting.MainIssue}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SubIssue Column -->
                <ng-container matColumnDef="SubIssue">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sub Issue</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.SubIssue}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentClass Column -->
                <ng-container matColumnDef="StudentClass">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Class</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.StudentClass}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Month Column -->
                <ng-container matColumnDef="Month">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Month</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.Month}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentType Column -->
                <ng-container matColumnDef="StudentType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student Type</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.StudentType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- NoOfStudents Column -->
                <ng-container matColumnDef="NoOfStudents">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>No Of Students</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.NoOfStudents}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ApprovalStatus Column -->
                <ng-container matColumnDef="ApprovalStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Approval Status</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.ApprovalStatus}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- ApprovedDate Column -->
                 <ng-container matColumnDef="ApprovedDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Approved Date</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{hmIssueReporting.ApprovedDate | date: "dd/MM/yyyy"}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let hmIssueReporting" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/hm-issue-reporting', 'view', hmIssueReporting.HMIssueReportingId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon class = "data-edit s-22">edit</mat-icon>
                        </button>

                        <button *ngIf="hmIssueReporting.ApprovalStatus == null" mat-icon-button                         
                        (click)="saveOrUpdateHMIssueApprovalDetails('Approved', hmIssueReporting.HMIssueReportingId)">
                            <mat-icon color="primary">check</mat-icon>
                        </button>

                        <button *ngIf="hmIssueReporting.ApprovalStatus == null" mat-icon-button                        
                        (click)="saveOrUpdateHMIssueApprovalDetails('Rejected', hmIssueReporting.HMIssueReportingId)">
                            <mat-icon color="primary">close</mat-icon>
                        </button>                         
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let hmIssueReporting; columns: displayedColumns;" class="hm-issue-reporting" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
